<template>
  <div class="responsive-container">
    <div class="canvas-container" :class="platform" :style="canvasStyle">
      <!--    <div class="canvas" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
            <p :style="{ color: textColor }">Sample Text</p>
          </div>-->

      <canvas ref="canvas"  :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
<!--    <button @click="downloadCanvasAsImage">download</button>-->
  </div>
</template>

<script>
export default {
  props: {
    theme: { type: Object, default: null },
    businessName:{
      type:String,
      default:''
    },
    reviewText:{
      type:String,
      default:''
    },
    reviewRating:{
      type:Number,
      default:5
    },
    reviewerName:{
      type:String,
      default:''
    },
    platform:{
      type:String,
      default:'facebook'
    },
    reviewerPhoto:{
      type:String,
      default:'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/social-icon.png'
    }
  },
  data(){
    return {
      currentTheme:this.theme,
      businessPhoneName:this.businessName,
    }
  },
  computed:{
    canvasHeight(){
      return this.platform === 'facebook' ? 630 : 1080;
    },
    canvasWidth(){
      return this.platform === 'facebook' ? 1200 : 1080;
    },
    canvasMaxHeight(){
      return 630;
    },
    canvasMaxWidth(){
      return this.platform === 'facebook' ? 1200 : 630;
    },
    canvasStyle() {
      return {
        'max-width': `${this.canvasMaxWidth}px`,
        'padding-bottom' : this.platform === 'facebook' ? '52.5%' : '82%',
        'max-height': `${this.canvasMaxHeight}px`
      };
    }
  },
  watch: {
    theme: {
      handler(newValue) {
        this.currentTheme = newValue;
        this.drawCanvas();
        this.$emit('themeChanged',newValue);
      },
      deep: true
    },
    businessName(newValue){
      this.businessPhoneName = newValue;
      this.drawCanvas();
    }
  },
  mounted() {
    this.drawCanvas();
  },
  methods: {
    downloadCanvasAsImage(){
      const canvas = this.$refs.canvas;
      console.log(canvas);
      let canvasUrl = canvas.toDataURL("image/jpeg", 0.5);
      console.log(canvasUrl);
      const createEl = document.createElement('a');
      createEl.href = canvasUrl;
      createEl.download = "download-this-canvas";
      createEl.click();
      createEl.remove();
      // Canvas2Image.saveAsPNG(this.$refs.canvas,1200,630)
    },
    hexToRgb(hex) {
      // Remove '#' if present
      hex = hex.replace('#', '');

      // Parse hexadecimal color code into RGB values
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      // Return an object containing the RGB values
      return { r, g, b };
    },
    addLightOpacityToHex(hexColor, opacity) {
      // Ensure opacity is within the valid range (0 to 1)
      opacity = Math.max(0, Math.min(1, opacity));

      // Convert hex color to RGB
      const r = parseInt(hexColor.substring(1, 3), 16);
      const g = parseInt(hexColor.substring(3, 5), 16);
      const b = parseInt(hexColor.substring(5, 7), 16);

      // Calculate the lighter color by blending with white
      const blendedR = Math.min(255, r + 80);
      const blendedG = Math.min(255, g + 80);
      const blendedB = Math.min(255, b + 80);

      // Apply opacity
      const rgbaString = `rgba(${blendedR}, ${blendedG}, ${blendedB}, ${opacity})`;

      return rgbaString;
    },
    drawCanvas() {
      const _this = this;
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');

      let fontSize;
      let maxWidth;
      let maxLines;
      let lineHeight;
      let textStartY;
      let textBoxHeight;
      let nameY = 100;
      let roundedBoxStarY = 425;
      switch(this.platform){
        case 'facebook':
          textBoxHeight = 300;
          if(this.currentTheme.layout === 'rounded-box'){
            fontSize = 30;
            lineHeight = 48;
            maxLines = 4;
            maxWidth = 1280;
            textStartY = 170;
            nameY = 190;
          }else{
            fontSize = 38;
            lineHeight = 58;
            maxLines = 5;
            maxWidth = 1350;
            textStartY = 220;
          }
          break;
        case 'instagram':
        case 'gbp':
          textBoxHeight = 750;
          if(this.currentTheme.layout === 'rounded-box'){
            fontSize = 45;
            nameY = 210;
            maxLines = 8;
            maxWidth = 800;
            textStartY = 400;
            lineHeight = 70;
            roundedBoxStarY = 850;
          }else{
            fontSize = 50;
            maxLines = 9;
            maxWidth = 820;
            textStartY = 450;
            lineHeight = 78;
          }
          break;
        default:
          maxWidth = 1350;
          maxLines = 5;
          lineHeight = 58;
          textStartY = 220;
          textBoxHeight = 300;
          if(this.currentTheme.layout === 'rounded-box'){
            fontSize = 30;
          }else{
            fontSize = 38;
          }
      }
      // Clear canvas
      // ctx.clearRect(0, 0, canvas.width, canvas.height);
      // Draw background image
      const backgroundImage = new Image();
      backgroundImage.src = this.currentTheme.src;
      backgroundImage.onload = function() {
        ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

        // Draw black opaque background after image

        switch(_this.currentTheme.overlay){
          case "dark":
            const rgbColor = _this.hexToRgb(_this.currentTheme.background_color);
            ctx.fillStyle = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.6)`;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            break;
          case "light":
            ctx.fillStyle = _this.addLightOpacityToHex(_this.currentTheme.background_color,.8)
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            break;
        }


        if(_this.currentTheme.layout === 'rounded-box'){
          // Define rectangle dimensions
          const rectWidth = 855;
          const rectHeight = textBoxHeight + 120;
          const cornerRadius = 40;

// Calculate starting point
          const startX = (canvas.width - rectWidth) / 2;
          const startY = (canvas.height - rectHeight) / 2 - 20;

// Draw rounded rectangle
          ctx.strokeStyle = _this.currentTheme.box_background_color;
          ctx.fillStyle = _this.currentTheme.box_background_color;
          ctx.beginPath();
          ctx.roundRect(startX, startY, rectWidth, rectHeight, cornerRadius);
          ctx.stroke();
          ctx.fill();
        }

        // Draw Google logo
        const googleLogo = new Image();
        googleLogo.src = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/google-icon.png';
        googleLogo.onload = function() {
          let startingX = canvas.width - 180;
          let startingY = 50;
          if(_this.currentTheme.layout === 'rounded-box') {
            if(_this.platform === 'facebook'){
              startingX = canvas.width - 145;
              startingY = 30;
              ctx.drawImage(googleLogo, startingX, startingY, 110, 110);
            }else{
              startingX = canvas.width - 100;
              startingY = 20;
              ctx.drawImage(googleLogo, startingX, startingY, 80, 80);
            }
          }else{
            ctx.drawImage(googleLogo, startingX, startingY, 80, 80);
          }
        };

        // Draw Icon
        const iconLogo = new Image();
        iconLogo.src = _this.reviewerPhoto;
        iconLogo.onload = function(){
          if(_this.currentTheme.layout === 'rounded-box') {
            ctx.drawImage(iconLogo,(canvas.width/ 2) - 60, 30);
          }else {
            ctx.drawImage(iconLogo, 70, 50);
          }
        }

        // Draw business name
        ctx.fillStyle = _this.currentTheme.phone_bg_color;
        ctx.font = 'bold 38px "Roboto", sans-serif';
        let businessNameText = _this.businessPhoneName;
        const textWidth = ctx.measureText(businessNameText).width;
        const textX = (canvas.width - textWidth) / 2;
        const textY = canvas.height - 26; // 30 pixels above the bottom
        ctx.fillRect(0, canvas.height - 75, canvas.width, 75); // Draw black bar
        ctx.fillStyle = _this.currentTheme.phone_text_color;
        ctx.fillText(businessNameText, textX, textY);

        // Draw stars
        let starPath;
        switch (true) {
          case _this.reviewRating === 1:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/1.png';
            break;
          case _this.reviewRating > 1 && _this.reviewRating < 2:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/1.5.png';
            break;
          case _this.reviewRating === 2:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/2.png';
            break;
          case _this.reviewRating > 2 && _this.reviewRating < 3:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/2.5.png';
            break;
          case _this.reviewRating === 3:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/3.png';
            break;
          case _this.reviewRating > 3 && _this.reviewRating < 4:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/3.5.png';
            break;
          case _this.reviewRating === 4:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/4.png';
            break;
          case _this.reviewRating > 4 && _this.reviewRating < 5:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/4.5.png';
            break;
          case _this.reviewRating === 5:
            starPath = 'https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/stars/5.png';
            break;
          default:
            // Handle default case if necessary
            break;
        }
        const starImage = new Image();
        starImage.src = starPath;
        starImage.onload = function() {
          if(_this.currentTheme.layout === 'rounded-box') {
            ctx.drawImage(starImage, (canvas.width/ 2) - 125, roundedBoxStarY, 250, 41);
          }else {
            ctx.drawImage(starImage, 220, 120, 250, 41);
          }
        };
        // Add Reviewer
        ctx.fillStyle = _this.currentTheme.text_color;
        if(_this.currentTheme.layout === 'rounded-box'){
          ctx.font = 'bold '+ fontSize +'px "Roboto", sans-serif';
          const reviewerText = _this.reviewerName;
          const reviewerWidth = ctx.measureText(reviewerText).width;
          const reviwertextX = (canvas.width - reviewerWidth) / 2;
          ctx.fillText(reviewerText, reviwertextX, nameY);
        }else{
          ctx.font = `bold 50px "Roboto", sans-serif`;
          ctx.fillText(_this.reviewerName,220,nameY)
        }
        ctx.font = `bold 50px "Roboto", sans-serif`;
        // Add Review Text
        if(_this.currentTheme.layout === 'rounded-box') {
          _this.drawTextWithEllipsis(_this.reviewText,`300 ${fontSize}px "Roboto", sans-serif`,lineHeight, ctx, canvas.width, maxWidth, maxLines, textStartY, _this.currentTheme.text_color);
        }else{
          _this.drawTextWithEllipsis(_this.reviewText,`bold ${fontSize}px "Roboto", sans-serif`,lineHeight, ctx, canvas.width, maxWidth, maxLines, textStartY, _this.currentTheme.text_color);
        }
        // Reset text align for re-rendering
        ctx.textAlign = 'left';
      };

    },
    drawTextWithEllipsis(text, font, lineHeight, ctx, canvasWidth, maxWidth, maxLines, textStartY, textColor) {

      const words = text.split(' ');
      let lines = [];
      let currentLine = '';

      // Construct the lines of text
      for (let word of words) {
        let testLine = currentLine + word + ' ';
        let metrics = ctx.measureText(testLine);
        let testWidth = metrics.width;
        if (testWidth > maxWidth) {
          // Push the current line to the lines array
          lines.push(currentLine.trim());
          // Start a new line with the current word
          currentLine = word + ' ';
        } else {
          currentLine = testLine;
        }
      }

      // Push the last line to the lines array
      lines.push(currentLine.trim());

      // Ensure the number of lines does not exceed maxLines
      const totalLines = lines.length;
      lines = lines.slice(0, maxLines);

      // Draw the lines of text
      for (let i = 0; i < lines.length; i++) {
        let y = textStartY + (i * lineHeight) + (300 / 2) - ((lines.length - 1) * lineHeight) / 2;
        let text = lines[i];
        if (i === 0) {
          text = '"' + text;
        }
        if (i === lines.length - 1) {
          if(totalLines > maxLines && words.length > 5){
            text += "...";
          }
          text += '"';
        }
        // Draw the line of text
        ctx.font = font;
        ctx.textAlign = 'center'; // Center text horizontally
        ctx.fillStyle = textColor;
        ctx.fillText(text, canvasWidth / 2, y,canvasWidth);
        // Move to the next line
        y += lineHeight;
      }
    }


















  },
};
</script>
<style scoped>
.canvas-container {
  position: relative;
  width: 100%; /* Make container fill its parent */
  /*height: 0;  Height is set by padding-bottom for aspect ratio */
  margin:auto;
}
/*.responsive-container{
  max-width: 630px;
  max-height: 630px;
}*/
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
@media screen and (min-width:991px) {
  .canvas-container.instagram, .canvas-container.gbp{
    padding-bottom: 100% !important;
  }
}
</style>
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
