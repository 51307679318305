import { render, staticRenderFns } from "./ReviewImageEditorTemplate.vue?vue&type=template&id=4b29749f&scoped=true"
import script from "./ReviewImageEditorTemplate.vue?vue&type=script&lang=js"
export * from "./ReviewImageEditorTemplate.vue?vue&type=script&lang=js"
import style0 from "./ReviewImageEditorTemplate.vue?vue&type=style&index=0&id=4b29749f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b29749f",
  null
  
)

/* custom blocks */
import block0 from "./ReviewImageEditorTemplate.vue?vue&type=custom&index=0&blockType=link&rel=preconnect&href=https%3A%2F%2Ffonts.googleapis.com"
if (typeof block0 === 'function') block0(component)

export default component.exports