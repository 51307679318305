<template>
  <div>
    <div class="row">
      <div class="col">
        <h2>Social Posting</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-5">
        <div class="mb-2">Themes</div>
        <div v-for="image in images" :key="image.id" class="image-container d-inline-block p-2">
<!--          <dynamic-editor :platform="type"
                          :theme=image
                          :business-name="user.business.business_name + ' - ' + user.business.formatted_phone"
                          :review-text="sampleText"
                          :review-rating="5"
                          reviewer-name="Ken Doe"
          ></dynamic-editor>-->
          <div class="text-center">
            <label :for="'image-checkbox-' + image.id" class="image-label m-0">
              <input
                  :id="'image-checkbox-' + image.id"
                  v-model="selectedImages"
                  type="checkbox"
                  :value="image.id"
                  class="image-checkbox"
                  @click="setSelectedImage(image)"
              />
              <span class="img-background text-right" :style="{ backgroundImage: `url(${image.src})`, opacity: isSelected(image.id) ? 1 : 0.5 }">
              <span class="check">
                <svg v-if="isSelected(image.id)" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100">
                  <circle cx="50" cy="50" r="45" fill="#4CAF50" />
                  <path d="M30 50 l15 15 l30 -30" fill="none" stroke="#FFF" stroke-width="10" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100"></svg>
              </span>
            </span>
            </label>
            <button class="btn btn-link p-0 d-block invisible edit-button w-100" @click="startEditing(image)">
              edit
            </button>
          </div>
        </div>
        <div>
          <button class="btn-primary btn" @click="saveSelected"><b-spinner v-if="savingSelected" small /> Save</button>
        </div>
      </div>
      <div class="col-sm-7 text-center">
<!--        <img v-if="selectedImage" :src="socialImage" class="social-img" />-->
        <dynamic-editor v-if="selectedImage"
            :platform="type"
            :theme=selectedImage
            :business-name="user.business.business_name + ' - ' + user.business.formatted_phone"
            :review-text="sampleText"
            :review-rating="5"
            reviewer-name="Ken Doe"
        ></dynamic-editor>
      </div>
    </div>
    <b-modal v-model="editThemes" title="Customize Theme" size="lg">
      <div class="row">
        <div class="col">
          <div v-if="editingImage">
            <dynamic-editor v-if="selectedImage"
                            :platform="type"
                            :business-name="user.business.business_name + ' - ' + user.business.formatted_phone"
                            :theme="editingImage"
                            :review-text="sampleText"
                            :review-rating="5"
                            reviewer-name="Ken Doe"
                            @themeChanged="themeChanged"
            ></dynamic-editor>
<!--            <img :src="editingSocialImage" class="social-img" @load="imgLoadedEvent"  />
            <div v-if="!imageLoaded" class="text-center mt-1">
              <b-spinner  small />
              Rendering Image
            </div>-->
            <div class="row mt-4">
              <div class="col-lg-3">
                <div>
                  <label class="d-block">Layout:</label>
                  <select v-model="editingImage.layout" class="form-control">
                    <option value="default">Square</option>
                    <option value="rounded-box">Rounded Box</option>
                  </select>
                </div>

                <div v-if="editingImage.overlay !== 'none'" class="mt-2">
                  <label class="d-block">Background Color:</label>
                  <div class="border-container rounded">
                    <div class="inner-container d-flex align-items-center">
                      <span class="flex-grow-1">{{decodeURIComponent(editingImage.background_color)}}</span>
                      <input v-model="editingImage.background_color" type="color" class="color-input align-self-end" />
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <label class="d-block">Text Color:</label>
                  <div class="border-container rounded">
                    <div class="inner-container d-flex align-items-center">
                      <span class="flex-grow-1">{{decodeURIComponent(editingImage.text_color)}}</span>
                      <input v-model="editingImage.text_color" type="color" class="color-input align-self-end" />
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <label class="d-block">Phone Background Color:</label>
                  <div class="border-container rounded">
                    <div class="inner-container d-flex align-items-center">
                      <span class="flex-grow-1">{{decodeURIComponent(editingImage.phone_bg_color)}}</span>
                      <input v-model="editingImage.phone_bg_color" type="color" class="color-input align-self-end" />
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <label class="d-block">Phone Text Color:</label>
                  <div class="border-container rounded">
                    <div class="inner-container d-flex align-items-center">
                      <span class="flex-grow-1">{{decodeURIComponent(editingImage.phone_text_color)}}</span>
                      <input v-model="editingImage.phone_text_color" type="color" class="color-input align-self-end" />
                    </div>
                  </div>
                </div>

                <div v-if="editingImage.layout === 'rounded-box'" class="mt-2">
                  <label class="d-block">Box Color:</label>
                  <div class="border-container rounded">
                    <div class="inner-container d-flex align-items-center">
                      <span class="flex-grow-1">{{decodeURIComponent(editingImage.box_background_color)}}</span>
                      <input v-model="editingImage.box_background_color" type="color" class="color-input align-self-end" />
                    </div>
                  </div>
                </div>

                <div class="mt-2 mb-4">
                  <label class="d-block">Overlay:</label>
                  <div class="">
                    <select v-model="editingImage.overlay" class="form-control">
                      <option value="light">Light</option>
                      <option value="dark">Dark</option>
                      <option value="none">None</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div>
                  <div class="d-flex mb-4">
                    <input v-model="searchQuery" class="form-control" placeholder="Search for photos">
                    <button class="btn btn-primary ml-2" @click="searchImages">Search</button>
                  </div>
                </div>
                <div v-if="loadingImages" class="text-center">Loading...</div>
                <div v-else>
                  <div class="grid-container">
                    <div v-for="photo in photos" :key="photo.id">
                      <b-modal v-model="photo.opened" size="xl">
                        <img :src="photo.src.original + '?' + pexelsImageString + '&fit=crop'" alt="Large Photo" style="max-width: 100%; height: auto; max-height:630px">
                        <div class="mt-2">
                          <div>
                            Source: Pexels
                          </div>
                          <div>
                            Photographer: <a :href="photo.photographer_url" target="_blank">{{photo.photographer}}</a>
                          </div>
                          <div>
                            URL: <a :href="photo.url" target="_blank">{{photo.url}}</a>
                          </div>
                        </div>
                        <template v-slot:modal-footer>
                          <b-button variant="light" @click="photo.opened = false"
                          >Close</b-button
                          >

                          <b-button variant="primary" @click="uploadImage(editingImage,photo)">
                            <b-spinner v-if="savingThemes" small />
                            <span v-else>Use as Background Image</span>
                          </b-button>
                        </template>
                      </b-modal>
                      <img :src="photo.src.original + '?' + pexelsImageString + '&fit=crop'" :alt="photo.alt" @click="photo.opened = true">
                    </div>
                  </div>
                  <div v-if="photos.length" class="d-flex justify-content-center mt-2">
                    <b-btn v-if="photoRequest.prev_page" variant="outline-primary" class="mr-1" @click="movePage(photoRequest.prev_page)"><i class="uil uil-angle-left"></i></b-btn>
                    <b-btn v-if="photoRequest.next_page" variant="outline-primary" class="ml-1" @click="movePage(photoRequest.next_page)"><i class="uil uil-angle-right"></i></b-btn>
                  </div>
                  <a v-if="photos.length" href="https://www.pexels.com" target="_blank" class="text-center d-block mt-2 font-size-11">Photo Search provided by Pexels</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="editThemes = false"
        >Close</b-button
        >

        <b-button variant="primary" @click="saveThemes">
          <b-spinner v-if="savingThemes" small />
          <span v-else>Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import DynamicEditor from '@components/Business/ReviewImageEditorTemplate.vue';
export default {
  components:{
    DynamicEditor
  },
  data(){
    return {
      user:{
        business:{
          business_name:null,
          business_phone:null,
          social_settings:{
            facebook_images:[],
            instagram_images:[],
            gbp_images:[]
          }
        }
      },
      searchQuery: '',
      photos: [],
      photoRequest:{},
      loadingImages: false,
      editingImage: null,
      editThemes:false,
      type:this.$route.params.platform,
      sampleText:'I recently had the pleasure of experiencing the outstanding service at Ken\'s business. From the moment I walked in, the staff was incredibly welcoming and attentive, and the atmosphere is warm and inviting, creating a comfortable environment. The quality of their work exceeding my expectations',
      // sampleText: 'This is a single line',
      selectedImage:null,
      images: [],
      savingSelected:false,
      savingThemes:false,
      selectedImages:[],
      imageLoaded:false,
      backgroundColor:'#000000',
      textColor:'#ffffff'
    }
  },
  computed:{
    socialImage(){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + `api/v1/sample-social-image?text=${this.sampleText}&platform=${this.type}&reviewer=Ken Doe&business_name=${this.user.business.business_name}&business_phone=${this.user.business.phone_number}&business_country=${this.user.business.country}&icon=https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/social-icon.png&background_image=${this.selectedImage.src}&background_color=${encodeURIComponent(this.selectedImage.background_color)}&text_color=${encodeURIComponent(this.selectedImage.text_color)}&overlay=${this.selectedImage.overlay}&layout=${this.selectedImage.layout}&box_background_color=${encodeURIComponent(this.selectedImage.box_background_color)}&phone_bg_color=${encodeURIComponent(this.selectedImage.phone_bg_color)}&phone_text_color=${encodeURIComponent(this.selectedImage.phone_text_color)}`;
    },
    pexelsImageString(){
      return this.type === 'instagram' ? 'w=1080&h=1080' : 'w=1200&h=630';
    },
    editingSocialImage(){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL) + `api/v1/sample-social-image?text=${this.sampleText}&reviewer=Ken Doe&business_name=${this.user.business.business_name}&business_phone=${this.user.business.phone_number}&business_country=${this.user.business.country}&icon=https://rengine.sfo3.cdn.digitaloceanspaces.com/socialreview/social-icon.png&background_image=${this.editingImage.src}&background_color=${encodeURIComponent(this.editingImage.background_color)}&text_color=${encodeURIComponent(this.editingImage.text_color)}&overlay=${this.editingImage.overlay}&platform=${this.type}&layout=${this.editingImage.layout}&box_background_color=${encodeURIComponent(this.editingImage.box_background_color)}&phone_bg_color=${encodeURIComponent(this.editingImage.phone_bg_color)}&phone_text_color=${encodeURIComponent(this.editingImage.phone_text_color)}`;
    }
  },
  watch:{
    editingImage: {
      handler() {
        this.imageLoaded = false;
      },
      deep: true
    }
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    const socialSettings = this.user?.business?.social_settings ?? null;
    if(this.user.business && socialSettings !== null && socialSettings.hasOwnProperty(`${this.type}_images`) && Array.isArray(socialSettings[`${this.type}_images`]) && socialSettings[`${this.type}_images`].length){
      if(this.user.business.social_settings.hasOwnProperty(`${this.type}_selected_images`) && this.user.business.social_settings[`${this.type}_selected_images`]) {
        this.selectedImages = this.user.business.social_settings[`${this.type}_selected_images`];
      }
      if(this.user.business.social_settings && this.user.business.social_settings.hasOwnProperty(`${this.type}_images`) && this.user.business.social_settings[`${this.type}_images`]){
        let currentIds = new Set(this.user.business.social_settings[`${this.type}_images`].map(obj => obj.id));
        this.images = this.user.business.social_settings[`${this.type}_images`];
        this.user.business.default_social_themes[`${this.type}`].forEach(obj => {
          if (!currentIds.has(obj.id)) {
            this.images.push(obj);
          }
        });
      }
    }else{
      this.images = this.user.business.default_social_themes[`${this.type}`] ?? [];
    }
    if(this.selectedImages && this.selectedImages.length){
      this.selectedImage = this.images.find(obj => obj.id === this.selectedImages[0]);
    }else{
      this.selectedImage = this.images[0];
      // Set on by default
      this.images.forEach(img => {
        this.selectedImages.push(img.id);
      })
    }

    // Retroactively add new elements to old saved themes

    if(!this.selectedImage.hasOwnProperty('phone_bg_color')){
      this.selectedImage.phone_bg_color = this.selectedImage.background_color;
    }
    if(!this.selectedImage.hasOwnProperty('phone_text_color')){
      this.selectedImage.phone_text_color = this.selectedImage.text_color;
    }
    if (!this.selectedImage.hasOwnProperty('layout')) {
      this.selectedImage = { ...this.selectedImage, layout: "default" };
    }
    if (!this.selectedImage.hasOwnProperty('box_background_color')) {
      this.selectedImage = { ...this.selectedImage, box_background_color: this.selectedImage.phone_bg_color };
    }
  },
  methods:{
    mergeObjectsById(currentArray, defaultArray) {
      let defaultObject = defaultArray.find(obj => !currentArray.some(currObj => currObj.id === obj.id));
      if (defaultObject) {
        currentArray.push(defaultObject);
      }
      return currentArray;
    },
    themeChanged(image){
      const index = this.images.findIndex(item => item.id === image.id);
      if (index !== -1) {
        this.$set(this.images, index, image);
      }
    },
    imgLoadedEvent(){
      this.imageLoaded = true;
    },
    movePage(page){
      this.loadingImages = true;
      this.$store
          .dispatch('business/imagepage',{
            page:page
          })
          .then((resp) => {
            this.photoRequest = resp;
            this.photos = resp.photos;
            this.loadingImages = false;
          })
          .catch(() => {
            this.loadingImages = false;
          })
    },
    searchImages(){
      this.loadingImages = true;
      this.$store
          .dispatch('business/queryimages',{
            query:this.searchQuery,
            platform:this.type
          })
          .then((resp) => {
            this.photoRequest = resp;
            this.photos = resp.photos;
            this.loadingImages = false;
          })
          .catch(() => {
            this.loadingImages = false;
          })
    },
    uploadImage(editingImage,photo){
      this.savingThemes = true;
      this.$store
          .dispatch('business/saveImage',{
            editing_image:editingImage,
            photo:photo,
            type:this.type,
            business_id:this.user.business.id
          })
          .then((resp) => {
            this.savingThemes = false;
            this.images = resp.images.map(image => {
              return {...image, src: image.src + '?cache=' + new Date().getTime()};
            });
            const thisImage = this.images.find(obj => obj.id === resp.image.id);
            this.editingImage = thisImage;
            this.selectedImage = thisImage;
            photo.opened = false;
          })
          .catch(() => {
            this.savingThemes = false;
          })
    },
    saveSelected(){
      this.savingSelected = true;
      this.$store
          .dispatch('business/saveSocialSelectedSettings',{
            business_id:this.user.business.id,
            type:this.type,
            images:this.selectedImages,
            themes:this.images
          })
          .then(() => {
            this.savingSelected = false;
          })
          .catch(() => {
            this.savingSelected = false;
          })
    },
    saveThemes(){
      this.savingThemes = true;
      this.$store
          .dispatch('business/saveSocialThemeSettings',{
            business_id:this.user.business.id,
            type:this.type,
            images:this.images
          })
          .then(() => {
            this.savingThemes = false;
            this.selectedImage = this.editingImage;
          })
          .catch(() => {
            this.savingThemes = false;
          })
    },
    startEditing(image){
      this.editThemes = true
      this.editingImage = image;

      // Retroactively add new elements to old saved themes
      // Retroactively add new elements to old saved themes
      if (!this.editingImage.hasOwnProperty('phone_bg_color')) {
        this.editingImage = { ...this.editingImage, phone_bg_color: this.editingImage.background_color };
      }
      if (!this.editingImage.hasOwnProperty('phone_text_color')) {
        this.editingImage = { ...this.editingImage, phone_text_color: this.editingImage.text_color };
      }
      if (!this.editingImage.hasOwnProperty('layout')) {
        this.editingImage = { ...this.editingImage, layout: "default" };
      }
      if (!this.editingImage.hasOwnProperty('box_background_color')) {
        this.editingImage = { ...this.editingImage, box_background_color: this.editingImage.phone_bg_color };
      }
    },
    isEditing(imageId) {
      return this.editingImage && this.editingImage.id === imageId;
    },
    setEditingImage(image) {
      this.editingImage = image;
    },
    isSelected(imageId) {
      return this.selectedImages.includes(imageId);
    },
    setSelectedImage(selected){
      this.selectedImage = selected;
    }
  }
}
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: auto auto;

  > div{
    padding: 3px;
    img{
      cursor:pointer;
      max-width: 100%;
      height: auto;
    }
  }
}

.border-container {
  border: 1px solid #e2e7f1; /* Set the border around the whole area */
  padding: 5px 10px; /* Add padding for better spacing */
  display: inline-block; /* Adjust display to your layout needs */
  width: 100%;
}

.border {
  display: flex;
  align-items: center;
}

.color-input {
  margin-left: 15px;
  border: 0;
  width: 30px; /* Set width */
  height: 30px; /* Set height */
}

.image-container:hover{
  .edit-button{
    visibility: visible !important;
  }
}
.edit-button{
  margin-top: -4px;
  vertical-align: top;
  color: dodgerblue;
  button{
    border: 2px solid #23b1dc;
    color: #23b1dc;
  }
}
  .social-img{
    max-width: 100%;
    height: auto;
    max-height: 630px;
  }
  .image-container {
    margin-bottom: -10px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .image-checkbox {
    display: none;
  }

  .image-label {
    cursor: pointer;
    position: relative;
  }


  .image-name {
    margin-left: 10px;
  }
  .image-radio {
    display: none;
  }
  .img-background{
    width:200px;
    height: 105px;
    display: inline-block;
    background-size: 100%;
  }
  /* Add custom styles to dim the background of selected options */
  .image-checkbox:checked + .img-background {
    border:2px solid #4CAF50;
    opacity: 1;
  }
.img-background:hover{
  opacity: .8 !important;
}
  /* Add custom styles for selected image */
  .image-radio:checked + .image-label .img-background {
    opacity: 1;
  }
</style>
